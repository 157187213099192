
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ImageContainer extends Vue {
  @Prop({ required: true }) readonly imagePath!: string;

  get backgroundImage() {
    return `background-image: url(${this.imagePath})`;
  }
}
