
import { Vue, Component, Prop } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";

@Component({ components: { PageSection } })
export default class PlaylistEmbed extends Vue {
  @Prop({ required: true }) playlistId!: string;
  @Prop({ required: true }) title!: string;
  @Prop() icon!: string;

  get ytSrc() {
    return (
      "https://www.youtube-nocookie.com/embed/videoseries?list=" +
      this.playlistId
    );
  }
}
