
import { Vue, Component, Prop } from "vue-property-decorator";
import { urlFromMedia } from "@/utils/UrlHelper";
import { zoneStore } from "@/store/typed";
import Media from "@/models/strapi/Media";

@Component({})
export default class Dashboard extends Vue {
  get items() {
    return zoneStore.dashboardItems;
  }

  get text() {
    return zoneStore.dashboardText;
  }

  iconUrl(icon: Media) {
    return urlFromMedia(icon);
  }
}
