
import { Vue, Component, Prop } from "vue-property-decorator";
import { News } from "../../models";
import { urlFromMedia } from "@/utils/UrlHelper";
import moment from "moment";

@Component({})
export default class NewsCard extends Vue {
  @Prop() readonly value!: News;

  get url() {
    if (!this.value.cover) {
      return "";
    }
    return urlFromMedia(this.value.cover, "medium");
  }

  get date() {
    return moment(this.value.date).format("DD MMM YYYY");
  }

  get newsDetailRoute() {
    return { name: "news_show", params: { id: this.value.id } };
  }

  goToDetail() {
    this.$router.push(this.newsDetailRoute);
  }
}
