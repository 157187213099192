
import { Vue, Component, Prop } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { zoneStore } from "@/store/typed";
import NewsCard from "@/components/News/Card.vue";

@Component({ components: { PageSection, NewsCard } })
export default class NewsPreview extends Vue {
  get news() {
    return zoneStore.news.slice(0, 3);
  }
}
