
import { Vue, Component, Prop } from "vue-property-decorator";

import VCarousel, { VCarouselSlide } from "@/components/VCarousel.vue";
import ImageContainer from "@/components/ImageContainer.vue";
import Media from "@/models/strapi/Media";
import { urlFromMedia } from "@/utils/UrlHelper";
@Component({
  components: {
    VCarousel,
    VCarouselSlide,
    ImageContainer,
  },
})
export default class ZoneImagesSlider extends Vue {
  @Prop({ required: true }) readonly images!: Media[];

  imageUrl(image: Media) {
    return urlFromMedia(image, "large");
  }
}
