
import { Vue, Component, Prop } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { zoneStore } from "@/store/typed";
import FileDownloader from "@/components/Common/FileDownloader.vue";
import marked from "marked";
@Component({ components: { PageSection, FileDownloader } })
export default class FrameworkAgreement extends Vue {
  private readMore = false;

  get zone() {
    return zoneStore.zone;
  }

  get summary() {
    return marked(this.zone.aq_summary);
  }

  get text() {
    return marked(this.zone.aq_text);
  }

  get documents() {
    return this.zone?.aq_documents;
  }

  toggleReadMore() {
    this.readMore = !this.readMore;
  }
}
