
import { Component, Vue, Prop } from "vue-property-decorator";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

import VIcon from "@/components/VIcon.vue";

export const VCarouselSlide = SwiperSlide;

export interface VCarouselPagination {
  el: string | HTMLElement;
  clickable: boolean;
  dynamicBullets?: boolean;
  dynamicMainBullets?: number;
}

export interface VCarouselBreakpointStyle {
  slidesPerView: number;
  spaceBetween: number;
}

export interface VCarouselBreakpoints {
  [key: number]: VCarouselBreakpointStyle;
}

export interface VCarouselNavigation {
  nextEl: string | HTMLElement;
  prevEl: string | HTMLElement;
}

export interface VCarouselAutoplay {
  delay: number;
  disableOnInteraction: boolean;
}

export interface VCarouselOptions {
  slidesPerView: number;
  spaceBetween: number;
  loop?: boolean;
  pagination?: VCarouselPagination;
  breakpoints?: VCarouselBreakpoints;
  navigation?: VCarouselNavigation;
  allowTouchMove?: boolean;
  centerInsufficientSlides?: boolean;
  autoplay?: VCarouselAutoplay;
  speed?: number;
}

export enum NavigationArrowsColor {
  White,
  Gray,
}

@Component({
  components: {
    Swiper,
    VIcon,
  },
})
export default class VCarousel2 extends Vue {
  @Prop({ default: 1 }) readonly slidesPerView!: number;
  @Prop({ default: 0 }) readonly spaceBetween!: number;

  @Prop({ default: true }) readonly navigation!: boolean;
  @Prop({ default: NavigationArrowsColor.White })
  arrowsColor!: NavigationArrowsColor;

  @Prop({ default: false }) readonly pagination!: boolean;
  @Prop({ default: false }) readonly loop!: boolean;
  @Prop({ default: false }) readonly dynamicBullets!: boolean;
  @Prop({ default: 1 }) readonly dynamicMainBullets!: number;

  @Prop({ default: undefined }) readonly breakpoints!: VCarouselBreakpoints;

  @Prop({ default: false }) readonly centerInsufficientSlides!: boolean;

  @Prop({ default: false }) readonly autoplay!: boolean;
  @Prop({ default: 1000 }) readonly autoplayDelay!: number;

  @Prop({ default: 300 }) readonly speed!: number;

  private uid: number = Math.floor(Math.random() * 10000);
  private navArrowsColors = { ...NavigationArrowsColor };

  get swiperOptions(): VCarouselOptions {
    const options: VCarouselOptions = {
      slidesPerView: this.slidesPerView,
      spaceBetween: this.spaceBetween,
      loop: this.loop,
      allowTouchMove: false,
      centerInsufficientSlides: this.centerInsufficientSlides,
      speed: this.speed,
    };

    if (this.pagination) {
      options.pagination = {
        el: `#${this.getComponentId("pagination")}`,
        clickable: true,
        dynamicBullets: this.dynamicBullets,
        dynamicMainBullets: this.dynamicMainBullets,
      };
      options.allowTouchMove = true;
    }

    if (this.navigation) {
      options.navigation = {
        nextEl: `#${this.getComponentId("navigation-next")}`,
        prevEl: `#${this.getComponentId("navigation-prev")}`,
      };
      options.allowTouchMove = true;
    }

    if (this.breakpoints) {
      options.breakpoints = { ...this.breakpoints };
    }

    if (this.autoplay) {
      options.autoplay = {
        delay: this.autoplayDelay,
        disableOnInteraction: false,
      };
    }

    options.speed = this.speed;

    return options;
  }

  private getComponentId(component: string) {
    return `${component}-${this.uid}`;
  }
}
