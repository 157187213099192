
import { Component, Vue } from "vue-property-decorator";
import Dashboard from "@/components/Home/Dashboard.vue";
import FrameworkAgreement from "@/components/Home/FrameworkAgreement.vue";
import NewsPreview from "@/components/Home/NewsPreview.vue";
import ZoneImagesSlider from "@/components/ZoneImagesSlider.vue";
import { zoneStore } from "@/store/typed";
import PlaylistEmbed from "@/components/Common/PlaylistEmbed.vue";

@Component({
  components: {
    PlaylistEmbed,
    Dashboard,
    FrameworkAgreement,
    NewsPreview,
    ZoneImagesSlider,
  },
})
export default class Home extends Vue {
  get zone() {
    return zoneStore.zone;
  }

  get playlistId() {
    return zoneStore.zone?.playlist_url;
  }

  get zoneImages() {
    return zoneStore.carouselImages;
  }
}
